import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { graphql, Link as GatsbyLink } from "gatsby";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import GraphQLErrorList from "../components/GraphQLErrorList";
import Layout from "../components/Layout";
import { mapEdgesToNodes } from "../lib/helpers";
import {
  albumType,
  categoryType,
  errorType,
  sieveType,
  themeType,
} from "../types";
import Section from "../components/Section";
import AlbumMedia from "../components/AlbumMedia";
import AlbumList from "../components/AlbumList";
import PaginationWrapper from "../components/PaginationWrapper";
import AlbumSieve from "../components/AlbumSieve";
import { normalize, stripPunctuation } from "../../../shared/helpers";
import SEO from "../components/SEO";

export const query = graphql`
  query AlbumPageQuery {
    albums: allSanityAlbum(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      ...AlbumEdge
    }
    themes: allSanityTheme {
      ...ThemeEdge
    }
    categories: allSanityCategory {
      ...CategoryEdge
    }
  }
`;

const filterAlbums = (albums, filter) => {
  const getIds = (item) => (item ? item.id : null);

  return albums.filter((album) => {
    if (filter.title) {
      const albumTitleCompareString = normalize(stripPunctuation(album.title))
        .split(" ")
        .join("");
      const filterTitleCompareString = normalize(
        stripPunctuation(filter.title).split(" ").join("")
      );

      if (!albumTitleCompareString.includes(filterTitleCompareString))
        return false;
    }

    if (filter.themes.length > 0) {
      const albumThemeIds = album.media
        .map((m) => m.themes)
        .flat()
        .map(getIds);
      const filterThemeIds = filter.themes.map(getIds);

      const doesNotIncludeThemes = !filterThemeIds.every((id) =>
        albumThemeIds.includes(id)
      );

      if (doesNotIncludeThemes) return false;
    }

    if (filter.categories.length > 0) {
      const albumCategoryIds = album.media.map((m) => m.category).map(getIds);
      const filterCategoryIds = filter.categories.map(getIds);
      const doesNotIncludeCategories = !filterCategoryIds.every((id) =>
        albumCategoryIds.includes(id)
      );

      if (doesNotIncludeCategories) return false;
    }

    return true;
  });
};

const sortAlbums = (albums, sort) => {
  const { prop, dir } = sort;
  const compareAlbums = (album1, album2) => {
    if (album1[prop] < album2[prop]) return dir === "ASC" ? -1 : 1;
    if (album1[prop] > album2[prop]) return dir === "ASC" ? 1 : -1;
    return 0;
  };

  if (dir !== "DESC" && dir !== "ASC") return albums;

  return [...albums].sort(compareAlbums);
};

function AlbumsPage({ data, errors, location }) {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const albumNodes = data && data.albums && mapEdgesToNodes(data.albums);
  const themeNodes = data && data.themes && mapEdgesToNodes(data.themes);
  const categoryNodes =
    data && data.categories && mapEdgesToNodes(data.categories);

  const albumSieve = {
    filter:
      location && location.state && location.state.filter
        ? { ...location.state.filter }
        : null,
    sort:
      location && location.state && location.state.sort
        ? { ...location.state.sort }
        : null,
  };

  const [sievedAlbums, setSievedAlbums] = useState(albumNodes);

  const handleSieveChange = useCallback(({ filter, sort }) => {
    setSievedAlbums(sortAlbums(filterAlbums(albumNodes, filter), sort));
    window.history.replaceState({ filter, sort }, "", "");
  });

  return (
    <Layout>
      <SEO title="Albums" />
      <Section heading="Latest Album" background="dark">
        <Typography variant="h3">
          <Link to={albumNodes[0].slug.current} component={GatsbyLink}>
            {albumNodes[0].title}
          </Link>
        </Typography>
        <AlbumMedia album={albumNodes[0]} />
      </Section>

      <Section heading="Search Albums" id="albumSearch">
        <Typography variant="h5" component="div">
          Results: {sievedAlbums.length}
        </Typography>

        <AlbumSieve
          themeOptions={themeNodes}
          categoryOptions={categoryNodes}
          onChange={handleSieveChange}
          initialSieve={albumSieve}
        />
        <PaginationWrapper
          perPage={10}
          data={sievedAlbums}
          render={(paginatedData) => <AlbumList albums={paginatedData} />}
        />
      </Section>
    </Layout>
  );
}

AlbumsPage.defaultProps = {
  errors: null,
  location: {
    state: {
      filter: null,
      sort: null,
    },
  },
};

AlbumsPage.propTypes = {
  data: PropTypes.shape({
    albums: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: albumType,
        })
      ),
    }),
    themes: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: themeType,
        })
      ),
    }),
    categories: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: categoryType,
        })
      ),
    }),
  }).isRequired,
  errors: PropTypes.arrayOf(errorType),
  location: PropTypes.shape({
    state: sieveType,
  }),
};

export default AlbumsPage;
